import PropTypes from 'prop-types'
import React from 'react'
import urls from '../constants.js'
import LineItemList from './LineItemList.jsx'
import OverviewTotal from './OverviewTotal.jsx'
import {removeLineItem, updateLineItem} from './api.js'

export default class CartOverview extends React.Component {
  // Component for the Cart Overview page where the users lineitems and
  // prices are enumerated and available for review before checking out.
  static propTypes = {
    lineitems: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      lineitems: this.props.lineitems,
    }

  }

  getLineData = (line, qty) => {
    return {
      'object_id': parseInt(line.object_id, 10),
      'content_type': line.content_type,
      'quantity': qty,
    }
  }

  removeLine = line => {
    let lineitems = this.state.lineitems
    let index = lineitems.indexOf(line)
    removeLineItem(this.getLineData(line, 1), () => {
      lineitems.splice(index, 1)
      this.setState({lineitems: lineitems})
    })
  }

  incrementLine = line => {
    let lineitems = this.state.lineitems
    let index = lineitems.indexOf(line)
    let new_qty = lineitems[index].quantity + 1


    // Subscription plans must have a quantity of 1
    if (line['stripe_plan']) {
      return;
    }
    updateLineItem(this.getLineData(line, new_qty), updated_li => {
      lineitems[index] = updated_li
      this.setState({lineitems: lineitems})
    })
  }

  decrementLine = line => {
    let lineitems = this.state.lineitems
    let index = lineitems.indexOf(line)
    let new_qty = lineitems[index].quantity - 1

    if (new_qty > 0) {
      updateLineItem(this.getLineData(line, new_qty), updated_li => {
        lineitems[index] = updated_li
        this.setState({lineitems: lineitems})
      })
    } else {
      this.removeLine(line)
    }
  }

  render() {
    let overview = null
    if (this.state.lineitems.length <= 0) {
      // TODO: add FlagList component for empty cart
      overview = (
        <div className='CartOverview__empty'>
          <h2>Your Cart Is Empty</h2>
          <p>Check out some of our <a href={urls.group_lessonURL}>language options</a>.</p>
        </div>
      )
    } else {
      overview = (
        <div className='CartOverview'>
          <OverviewTotal lineitems={this.props.lineitems} />
          <div className='CartOverview__list'>
            <h2>Cart</h2>
            <LineItemList
              lineitems={this.props.lineitems}
              allowEdits={true}
              removeLine={this.removeLine}
              incrementLine={this.incrementLine}
              decrementLine={this.decrementLine}
            />
          </div>
        </div>
      )
    }

    return (
      <section>{overview}</section>
    )
  }
}
