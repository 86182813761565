import $ from 'jquery'
require('./vendor/jquery.smartmenus.min.js') // plugin doesn't support ES modules

export default function initNavMenu() {
  const $menu = $('#navigation-bar');

  $menu.smartmenus({
    subMenusSubOffsetX: 6,
    subMenusSubOffsetY: -8,
    markCurrentItem: true,
    markCurrentTree: true
  });

  $('.hamburger').on('click',function() {
    const $this = $(this);

    $this.toggleClass('active');
    if ($this.hasClass('active')) {
      $menu.slideDown(function(){$menu.css('display','block')});
    }
    else {
      $menu.slideUp(function(){$menu.css('display','')});
    }

  });
}


