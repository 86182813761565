import $ from 'jquery';
require('./vendor/jquery.form.min.js')
require('select2');

const templateSelection = function (state) {
  if (!state.id ) {
    var $state = $('<span class="placeholder">' + state.text + '</span>');
    return $state;
  } else {
    return state.text;
  }
}

const initSelect = function() {
  const select2args = {};

  if ($(this).is('.multiplechoicefield[placeholder][placeholder!=""]')) {
    select2args.placeholder = $(this).attr('placeholder');
  }
  else {
    select2args.templateSelection = templateSelection;
  }

  $(this).select2(select2args);
}

const initAjaxForm = function(){
  $(this).ajaxForm({
      beforeSerialize: function($form) {
        const { pathname, search } = document.location;
        $form.find('input[type="hidden"][name="page_url"]').val(pathname + search);
      },
      beforeSubmit: function(arr, $form) {
        $form.find('.field-error').remove();
        return true;
      },
      success: (response) => {
      const $plugin = $(this).closest('.v2-plugin-dynamic-form');
      const $form = $(this).replaceWith(`<div class="form-response">${response.message}<br/>Click <a class="form-reset">here</a> to fill out form again.</div>`);
      $plugin.data('form', $form);
      $plugin.find('.form-intro').hide();

    },
    error: (response) => {
      if (response.status == 400) {
        const { responseJSON: { errors } } = response;
        for (const name in errors) {
          $(this).find(`[name="${name}"]`).closest('.field')
            .append(`<span class="field-error">${errors[name].join('<br/>')}</span>`);
        }
      }
    }
  });
}

export default function initDynamicForms() {

    $(function() {

    $('.v2-plugin-dynamic-form').on('click','.form-reset', function(){
      const $plugin = $(this).closest('.v2-plugin-dynamic-form');
      const $form = $plugin.data('form');

      if ($form) {
        initAjaxForm.call($form);
        $form.resetForm();
        $form.find('.field-error').remove();
        $plugin.find('.form-response').replaceWith($form);
        $plugin.find('.form-intro').show();
        $plugin.find('select').each(initSelect);
      }
    });

    $('.v2-plugin-dynamic-form form').each(initAjaxForm)
    $('.v2-plugin-dynamic-form select').each(initSelect);

  });
}